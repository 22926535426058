import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import ItemTableRow from './ItemTableRow';

class ItemsTable extends Component {
  renderItems = () => {
    return this.props.items.map((i, idx) => {
      return <ItemTableRow key={i._id} idx={idx} item={i} />;
    });
  };

  render() {
    return (
      <div className="col-lg-12 items-table-container ">
        <Table responsive striped>
          <thead>
            <tr>
              <th>#</th>
              <th className="items-table__title">Название</th>
              <th>Размер</th>
              <th>Цена</th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {this.renderItems()}
          </tbody>
        </Table>
      </div>
    );
  }
}

export default ItemsTable;
