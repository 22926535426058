export const sortItems = (items, value, direction) => {
  const order = `${value}_${direction}`;

  switch (order) {
    case 'title_asc':
      return [...items].sort((a, b) => {
        if (a.title < b.title) return -1;
        if (a.title > b.title) return 1;
        return 0;
      });
    case 'title_desc':
      return [...items].sort((a, b) => {
        if (a.title > b.title) return -1;
        if (a.title < b.title) return 1;
        return 0;
      });
    case 'price_desc':
      return [...items].sort((a, b) => {
        if (a.price.listPrice > b.price.listPrice) return -1;
        if (a.price.listPrice < b.price.listPrice) return 1;
        return 0;
      });
    default:
      return [...items].sort((a, b) => {
        if (a.price.listPrice < b.price.listPrice) return -1;
        if (a.price.listPrice > b.price.listPrice) return 1;
        return 0;
      });
  }
};
