const actions = {
  // CART ACTIONS
  GET_CART: 'GET_CART',
  GET_CART_REJECTION: 'GET_CART_REJECTION',
  ADD_TO_CART: 'ADD_TO_CART',
  ADD_TO_CART_REJECTION: 'ADD_TO_CART_REJECTION',
  UPDATE_CART: 'UPDATE_CART',
  UPDATE_CART_REJECTION: 'UPDATE_CART_REJECTION',
  DELETE_CART_ITEM: 'DELETE_CART_ITEM',
  DELETE_CART_ITEM_REJECTION: 'DELETE_CART_ITEM_REJECTION',
  SEND_ORDER: 'SEND_ORDER',
  SEND_ORDER_REJECTION: 'SEND_ORDER_REJECTION',

  // PRODUCTS ACTIONS
  GET_PRODUCT: 'GET_PRODUCT',
  GET_PRODUCT_REJECTION: 'GET_PRODUCT_REJECTION',
  POST_PRODUCT: 'POST_PRODUCT',
  POST_PRODUCT_REJECTION: 'POST_PRODUCT_REJECTION',
  DELETE_PRODUCT: 'DELETE_PRODUCT',
  DELETE_PRODUCT_REJECTION: 'DELETE_PRODUCT_REJECTION',
  UPDATE_PRODUCT: 'UPDATE_PRODUCT',
  RESET_BUTTON: 'RESET_BUTTON',

  // CATEGORIES ACTIONS
  GET_CATEGORIES: 'GET_CATEGORIES',
  GET_CATEGORIES_REJECTION: 'GET_CATEGORIES_REJECTION',

  // MODAL ACTIONS
  OPEN_MODAL: 'OPEN_MODAL',
  CLOSE_MODAL: 'CLOSE_MODAL',

  //SORT_ACTIONS
  SET_VIEW: 'SET_VIEW',
  SET_OREDER: 'SET_OREDER'
};

export default actions;
