import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { getAllCategoriesSelector } from '../../selectors/categorySelector';
import { getAllProductsSelector } from '../../selectors/productSelectors';
import { getCategories } from '../../actions/categoriesActions';
import { getProducts } from '../../actions/itemsActions';
import Item from './item';
import SubListItem from './SubListItem';

import MyCarousel from '../common/MyCarousel';

class CategoryList extends Component {
  componentDidMount() {
    this.props.getProducts();
    this.props.getCategories();
  }

  renderCategories = () => {
    return this.props.categories
      .filter(c => c.isRoot)
      .sort((c1, c2) => (c1.orderIdx > c2.orderIdx ? 1 : -1))
      .map(c => {
        return (
          <Col xs={12} sm={6} md={3} key={c._id}>
            <SubListItem
              title={c.title}
              imgSrc={c.img}
              id={c._id}
              hasSub={c.subCategories.length > 0}
            />
          </Col>
        );
      });
  };

  rednderSpecialOfferItems = () => {
    return this.props.items
      .filter(i => i.onSale)
      .map(item => (
        <Item
          _id={item._id}
          title={item.title}
          description={item.description}
          images={item.images}
          price={item.price}
          specialOffer={item.onSale}
          unit={item.unit}
          dimensions={item.dimensions}
          key={item._id}
        />
      ));
  };

  render() {
    if (this.props.categories.length > 0) {
      return (
        <div className="category-content">
          <div className="content">
            <div className="special-offer-list hidden-xs hidden-sm">
              <Row
                className="special-offer-list-row"
                style={{ marginTop: '15px' }}
              >
                <h1 className="content__heading">Специальное предложение</h1>
              </Row>
              <Row>
                <Col
                  md={9}
                  style={{
                    paddingLeft: '50px',
                    paddingRight: '50px',
                    textAlign: 'center'
                  }}
                >
                  {this.props.items.length > 0 ? (
                    <MyCarousel
                      slides={this.rednderSpecialOfferItems()}
                      //refactor this code
                      history={this.props.history}
                    />
                  ) : null}
                </Col>
                <Col md={3}>
                  <div className="link-to-special-offer">
                    <Link to="/special_offer">
                      <span className="link-to-special-offer_text">
                        Посмотреть все товары по акции
                      </span>
                    </Link>
                  </div>
                </Col>
              </Row>
            </div>
            <Row style={{ marginTop: '55px' }}>
              <h1 className="content__heading">Категории</h1>
            </Row>
            <Row className="padding-at-xs main-category-container">
              {this.renderCategories()}
            </Row>
          </div>
        </div>
      );
    } else {
      return <div className="loader">Loading...</div>;
    }
  }
}

const mapStateToProps = ({ filter, categories, products }) => ({
  // filter,
  items: getAllProductsSelector(products),
  categories: getAllCategoriesSelector(categories)
});

export default connect(mapStateToProps, {
  getCategories,
  // setFilter,
  getProducts
})(CategoryList);
