import actions from './actions';

import axios from 'axios';
// GET A product
export function getProducts() {
  return function(dispatch) {
    axios
      .get('/api/products')
      .then(response => {
        dispatch({ type: actions.GET_PRODUCT, payload: response.data });
      })
      .catch(err => {
        dispatch({ type: actions.GET_PRODUCT_REJECTION, payload: err });
      });
  };
}

export function resetButton(products) {
  return {
    type: actions.RESET_BUTTON
  };
}
