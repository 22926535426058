import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addToCart, updateCart } from '../../actions/cartActions';
import { Link } from 'react-router-dom';

class ItemTableRow extends Component {
  state = {
    inCart: false
  };

  isInCart() {
    return this.props.cart.forEach(item => {
      if (item._id === this.props.item._id) {
        this.setState({ inCart: true });
      }
    });
  }

  componentDidUpdate() {
    if (!this.state.inCart) {
      this.isInCart();
    }
  }

  componentDidMount() {
    if (!this.state.inCart) {
      this.isInCart();
    }
  }

  handleCart = () => {
    const { _id, title, description, price, images } = this.props.item;
    const itmes = [
      ...this.props.cart,
      {
        _id,
        title,
        description,
        images,
        price,
        quantity: 1
      }
    ];
    // CHECK IF THE CART IS EMPTY
    if (this.props.cart.length > 0) {
      let itemIndex = this.props.cart.findIndex(item => item._id === _id);

      itemIndex < 0
        ? this.props.addToCart(itmes)
        : this.props.updateCart(_id, 1, this.props.cart);
    } else {
      this.props.addToCart(itmes);
    }
  };

  render() {
    const { item, idx } = this.props;
    return (
      <tr key={item._id} className="item-table-row">
        <td>{idx + 1}</td>
        <td>{item.title}</td>
        <td>{item.dimensions}</td>
        <td className="items-tables__price">
          {item.price.listPrice} руб./{item.unit}
        </td>
        <td>
          {!this.state.inCart ? (
            <button onClick={this.handleCart} className="item-table__btn">
              <span>
                Добавить<br />в корзину
              </span>
            </button>
          ) : (
            <Link to="/cart">
              <button className="item-table__btn item-table__btn--blue">
                <span>
                  Перейти<br />в корзину
                </span>
              </button>
            </Link>
          )}
        </td>
      </tr>
    );
  }
}

const mapStateToProps = state => ({
  cart: state.cart.cart
});

export default connect(mapStateToProps, {
  addToCart,
  updateCart
})(ItemTableRow);
