import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Table } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import ym from 'react-yandex-metrika';
import ReactGA from 'react-ga';

import withSidebar from '../common/hocWithSidebar';
import {
  getProductSelector,
  isInCartSlector
} from '../../selectors/productSelectors';
import { addToCart, updateCart } from '../../actions/cartActions';
import { getActiveCategoryByProductId } from '../../selectors/categorySelector';
import { setFilter } from '../../actions/filterActions';

// http://localhost:3000/categories/subs/5960c4beac4d3a1e0e02ce3a/5960c4beac4d3a1e0e02ce40

class ItemInfo extends Component {
  state = {
    activeImg: 0
  };

  componentWillReceiveProps(nextProps) {
    const { filter, setFilter } = this.props;
    if (filter !== nextProps.activeCategory) {
      setFilter(nextProps.activeCategory);
    }
  }

  setActiveImg = idx => () => {
    this.setState({
      activeImg: idx
    });
  };

  renderAllImages = imgs => {
    return imgs.map((i, idx) => {
      return (
        <li
          key={idx}
          className={idx === this.state.activeImg ? 'active-img' : null}
          onClick={this.setActiveImg(idx)}
        >
          <img src={`/api/img/${i}`} alt={this.props.product.title} />
        </li>
      );
    });
  };

  renderCharacteristics = () => {
    const { dimensions, characteristics } = this.props.product;
    return [
      {
        title: 'Размеры',
        value: dimensions
      },
      ...characteristics
    ].map((char, idx) => {
      return (
        <tr key={idx}>
          <td>{char.title}</td>
          <td>{char.value}</td>
        </tr>
      );
    });
  };

  addToCart = () => {
    const { _id, title, description, price, images } = this.props.product;
    const items = [
      ...this.props.cart,
      {
        _id,
        title,
        description,
        images,
        price: price.listPrice,
        quantity: 1
      }
    ];

    //Yandex Metrika
    ym('reachGoal', 'toCartProductPage', { title: this.props.product.title });

    //google Analytics
    ReactGA.event({
      category: 'Client',
      action: 'toCartProductPage'
    });

    // CHECK IF THE CART IS EMPTY
    if (this.props.cart.length > 0) {
      let itemIndex = this.props.cart.findIndex(i => i._id === _id);

      itemIndex < 0
        ? this.props.addToCart(items)
        : this.props.updateCart(_id, 1, this.props.cart);
    } else {
      this.props.addToCart(items);
    }
  };

  render() {
    const { product } = this.props;

    if (product) {
      const { title, images, unit, description } = product;

      // HELMET
      const metaDescription = `Вы можете приобрести ${title.toLowerCase()} на базе строительных материалов Stalin Pskov в г. Пскове по выгодным ценам.`;

      return (
        <div className="poduct-info">
          <Helmet>
            <title>{title} - купить в г. Пкове - Stalin Pskov</title>
            <meta name="description" content={metaDescription} />
          </Helmet>

          <h1 className="content__heading">{title}</h1>
          <div className="poduct-info-top">
            <Row>
              <Col sm={7}>
                <div className="poduct-info-img">
                  <img
                    src={`/api/img/${images[this.state.activeImg]}`}
                    alt="title"
                  />
                </div>
                <ul className="poduct-info-images">
                  {this.renderAllImages(images)}
                </ul>
              </Col>
              <Col sm={5}>
                <div className="product-info-price">
                  <h2>
                    {product.price.listPrice}
                    <br /> <span>руб. / {unit}</span>
                  </h2>
                </div>
                {!this.props.inCart ? (
                  <button
                    onClick={this.addToCart}
                    className="item__btn item__btn--red"
                  >
                    <span>
                      Добавить<br />в корзину
                    </span>
                  </button>
                ) : (
                  <Link to="/cart">
                    <button className="item__btn item__btn--blue">
                      <span>
                        Перейти<br />в корзину
                      </span>
                    </button>
                  </Link>
                )}
                <h3>Характеристики</h3>
                <Table responsive striped>
                  <tbody>{this.renderCharacteristics()}</tbody>
                </Table>
              </Col>
            </Row>
            <hr />
            <h3 className="product-info-description-heading">Описание</h3>
            <div
              className="product-info-description"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </div>
        </div>
      );
    } else {
      return <div className="loader">Loading...</div>;
    }
  }
}

const mapStateToProps = ({ categories, products, cart, filter }, { match }) => {
  return {
    product: getProductSelector(products, match.params.id),
    activeCategory: getActiveCategoryByProductId(
      categories,
      products,
      match.params.id
    ),
    inCart: isInCartSlector(cart.cart, match.params.id),
    cart: cart.cart,
    filter
  };
};

export default withSidebar(
  connect(mapStateToProps, {
    setFilter,
    addToCart,
    updateCart
  })(ItemInfo)
);
