import React from 'react';
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';
import ym from 'react-yandex-metrika';

class ScrollToTop extends React.Component {
  componentDidMount() {
    //Google analytics
    ReactGA.initialize('UA-110741798-1');
    // ReactGA.initialize('AW-878651912');
    ReactGA.pageview(this.props.location.pathname);
    // Yandex metrika
    ym('hit', this.props.location.pathname);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      //Google analytics
      ReactGA.pageview(this.props.location.pathname);
      // Yandex metrika
      ym('hit', this.props.location.pathname);

      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
