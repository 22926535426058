import actions from './actions';
import { loadState } from '../helpers/localStorage';
import axios from 'axios';
import ym from 'react-yandex-metrika';
import ReactGA from 'react-ga';

export function getCart() {
  const cart = loadState();
  return { type: actions.GET_CART, payload: cart };
}

export function addToCart(cart) {
  return { type: actions.ADD_TO_CART, payload: cart };
}

export function updateCart(_id, unit, cart) {
  let booksToUpdate = cart;
  let idxToUpdate = booksToUpdate.findIndex(book => book._id === _id);
  booksToUpdate[idxToUpdate].quantity =
    booksToUpdate[idxToUpdate].quantity + unit;
  return { type: actions.UPDATE_CART, payload: booksToUpdate };
}

export const sendOrder = order => {
  return function(dispatch) {
    axios
      .post('/api/order', order)
      .then(res => {
        dispatch({ type: actions.SEND_ORDER });
        dispatch({ type: actions.OPEN_MODAL });

        // Yandex Metrika and Google Analytics
        ym('reachGoal', 'sendOrder', { totalValue: order.totalAmount });
        ReactGA.event({
          category: 'Client',
          action: 'sendOrder'
        });
      })
      .catch(err => {
        dispatch({ type: actions.SEND_ORDER_REJECTION, payload: err });
      });
  };
};

export function deleteCartItem(cart) {
  return { type: actions.DELETE_CART_ITEM, payload: cart };
}
