import axios from 'axios';
import actions from './actions';

export const getCategories = () => {
  return function(dispatch) {
    axios.get('/api/categories')
      .then(response => {
        dispatch({type: actions.GET_CATEGORIES, payload: response.data})
      })
      .catch(err => {
        dispatch({type: actions.GET_CATEGORIES_REJECTION, payload: err})
      })
  }
}
