import React from 'react';
import { connect } from 'react-redux';
import { addToCart, updateCart } from '../../actions/cartActions';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';
import ym from 'react-yandex-metrika';

class Item extends React.Component {
  state = {
    inCart: false
  };

  isInCart() {
    return this.props.cart.forEach(item => {
      if (item._id === this.props._id) {
        this.setState({ inCart: true });
      }
    });
  }

  componentDidUpdate() {
    if (!this.state.inCart) {
      this.isInCart();
    }
  }

  componentDidMount() {
    if (!this.state.inCart) {
      this.isInCart();
    }
  }

  handleCart = () => {
    const { _id, title, description, price, images } = this.props;
    const items = [
      ...this.props.cart,
      {
        _id,
        title,
        description,
        images,
        price: price.listPrice,
        quantity: 1
      }
    ];

    //google Analytics
    ReactGA.event({
      category: 'Client',
      action: 'toCart'
    });

    //yandex metrika
    ym('reachGoal', 'toCart', { title: this.props.title });

    // CHECK IF THE CART IS EMPTY
    if (this.props.cart.length > 0) {
      let itemIndex = this.props.cart.findIndex(i => i._id === _id);

      itemIndex < 0
        ? this.props.addToCart(items)
        : this.props.updateCart(_id, 1, this.props.cart);
    } else {
      this.props.addToCart(items);
    }
  };

  render() {
    return (
      <div className="item">
        <div
          className="item__img-contaner"
          style={{
            backgroundImage: 'url(/api/img/' + this.props.images[0] + ')'
          }}
        />
        {/* <Image src={this.props.images} responsive className="item__img"/> */}

        <h6 className="item__title">{this.props.title}</h6>
        {/* <p className="item__description">
              {this.props.description}
            </p> */}
        <h6 className="item__dimensions">
          Размер:{' '}
          <span className="item__dimensions-units">
            {this.props.dimensions}
          </span>
        </h6>
        <h6 className="item__price">
          Цена:{' '}
          <span>
            {this.props.price.listPrice} руб. / {this.props.unit}
          </span>
        </h6>
        <div className="item-btn-container">
          {!this.state.inCart ? (
            <button
              onClick={this.handleCart}
              className="item__btn item__btn--red"
            >
              <span>
                Добавить<br />в корзину
              </span>
            </button>
          ) : (
            <Link to="/cart">
              <button className="item__btn item__btn--blue">
                <span>
                  Перейти<br />в корзину
                </span>
              </button>
            </Link>
          )}
          <Link to={`/product/${this.props._id}`}>
            <button className="item__btn item__btn--blue">
              <span>Подробнее</span>
            </button>
          </Link>
        </div>

        <div className="item__badge">
          {!this.props.specialOffer ? (
            ''
          ) : (
            <div className="item__action-badge">Акция</div>
          )}
          {!this.state.inCart ? (
            ''
          ) : (
            <div className="item__cart-badge">В корзине</div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  cart: state.cart.cart
});

export default connect(mapStateToProps, {
  addToCart,
  updateCart
})(Item);
