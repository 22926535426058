import React from 'react';
import { Image, Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { getCart } from '../../actions/cartActions';

import Catalog from './stickyHeaderCatalog';

class StickyHeader extends React.Component {
  state = {
    visible: false
  };

  componentDidMount() {
    if (window.innerWidth > 760) {
      window.addEventListener('scroll', this.handleScroll);
    } else {
      this.setState({
        visible: true
      });
    }
  }

  componentWillUnmount() {
    if (window.innerWidth > 760) {
      window.removeEventListener('scroll', this.handleScroll);
    }
  }

  handleScroll = event => {
    let scrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;

    if (scrollTop > 100) {
      this.setState({
        visible: true
      });
    }

    if (scrollTop < 100) {
      this.setState({
        visible: false
      });
    }
  };

  render() {
    return (
      <div className={this.state.visible ? 'sticky' : 'sticky--hidden'}>
        <Link to="/">
          <img
            className="sticky__logo"
            src="/images/smallLogoSite4.png"
            alt="logo"
          />
        </Link>

        <p className="sticky__address">
          г.Псков, Октябрьский&nbsp;пр.&nbsp;56
          <br />
          тел. (8112) 61-04-54
          <br />
          +7-911-371-90-20
        </p>

        <div className="hero__icons">
          <Link to="/cart">
            <div className="hero__cart">
              <Image src="/images/cart.png" className="hero__icons-cart" />
              {this.props.totalQty > 0 ? (
                <Badge className="badge">{this.props.totalQty}</Badge>
              ) : (
                ''
              )}
            </div>
          </Link>
        </div>
        <Catalog />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    totalQty: state.cart.totalQty,
    filter: state.filter
  };
}

export default connect(mapStateToProps, { getCart })(StickyHeader);
