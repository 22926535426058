import React, { Component } from 'react';
import FaAngleLeft from 'react-icons/lib/fa/angle-left';
import FaAngleRight from 'react-icons/lib/fa/angle-right';
import PropTypes from 'prop-types';

class MyCarousel extends Component {
  static propTypes = {
    slides: PropTypes.array.isRequired,
    interval: PropTypes.number.isRequired,
    transition: PropTypes.number.isRequired,
    itemInSlide: PropTypes.number.isRequired,
    //refactor this code
    history: PropTypes.object.isRequired
  };

  static defaultProps = {
    slides: [0, 1, 2, 3, 4, 5, 6, 7],
    interval: 3000,
    transition: 1500,
    itemInSlide: 3
  };

  state = {
    activeSlide: 0,
    prevSlide: this.props.slides.length - 1,
    nextSlide: 1,
    sliding: true,
    direction: 'left'
  };

  componentDidMount() {
    this.interval = window.setInterval(this.startCarousel, this.props.interval);
  }

  componentWillUnmount() {
    this.stopCarousel();
    window.clearTimeout(this.timeout);
  }

  renderSlides = direction => {
    const slideItems = this.props.slides;
    const { itemInSlide } = this.props;
    const slidesArr = [];
    let slide;

    for (let i = 0; i < slideItems.length; i++) {
      if (i % itemInSlide === 0) {
        slide = [];
      }

      slide.push(slideItems[i]);
      if ((i + 1) % itemInSlide === 0 || i + 1 === slideItems.length) {
        slidesArr.push(slide);
      }
    }

    return slidesArr.map((slide, idx) => {
      let style;
      switch (true) {
        case idx === this.state.activeSlide:
          style = {
            left: 0,
            zIndex: 1,
            opacity: 1
          };
          break;
        case idx === this.state.nextSlide:
          style = {
            left: '100%',
            zIndex: direction === 'left' ? -1 : 1,
            opacity: 1
          };
          break;
        case idx === this.state.prevSlide:
          style = {
            left: '-100%',
            zIndex: direction === 'left' ? 1 : -1,
            opacity: 1
          };
          break;
        default:
          style = {
            zIndex: -10,
            opacity: 1
          };
      }

      style.transition = `left ${
        this.props.transition
      }ms cubic-bezier(0.42, 0, 0.58, 1)`;

      return (
        <div className="my-carousel-slide" key={idx} style={style}>
          {slide.map((item, index) => (
            <div
              className="my-carousel-slide-item"
              key={index + '10'}
              onClick={() => {
                this.props.history.push(`/product/${item.props._id}`);
              }}
            >
              {item}
            </div>
          ))}
        </div>
      );
    });
  };

  startCarousel = () => {
    this.showNext();
  };

  stopCarousel = () => {
    window.clearInterval(this.interval);
    this.interval = null;
    this.setState({ sliding: true });
  };

  startCarouselOnMouseLeave = () => {
    this.setState({ sliding: false });
    this.timeout = window.setTimeout(() => {
      if (!this.interval) {
        this.interval = window.setInterval(
          this.startCarousel,
          this.props.interval
        );
      }
    }, 1000);
  };

  showNext = () => {
    const { slides, itemInSlide } = this.props;
    const length = Math.ceil(slides.length / itemInSlide);
    this.setState(prevState => {
      const prevSlide =
        prevState.prevSlide + 1 >= length ? 0 : prevState.prevSlide + 1;
      const activeSlide =
        prevState.activeSlide + 1 >= length ? 0 : prevState.activeSlide + 1;
      const nextSlide =
        prevState.nextSlide + 1 >= length ? 0 : prevState.nextSlide + 1;

      return {
        activeSlide,
        nextSlide,
        prevSlide,
        direction: 'left',
        sliding: true
      };
    });

    this.timeout = window.setTimeout(() => {
      this.setState({ sliding: false });
    }, this.props.transition);
  };

  showPrev = () => {
    const { slides, itemInSlide } = this.props;
    const length = Math.ceil(slides.length / itemInSlide);
    this.setState(prevState => {
      const prevSlide =
        prevState.prevSlide - 1 < 0 ? length - 1 : prevState.prevSlide - 1;
      const activeSlide =
        prevState.activeSlide - 1 < 0 ? length - 1 : prevState.activeSlide - 1;
      const nextSlide =
        prevState.nextSlide - 1 < 0 ? length - 1 : prevState.nextSlide - 1;

      return {
        activeSlide,
        nextSlide,
        prevSlide,
        sliding: true,
        direction: 'right'
      };
    });

    this.timeout = window.setTimeout(() => {
      this.setState({ sliding: false });
    }, this.props.transition);
  };

  toPrev = () => {
    if (!this.state.sliding) {
      window.clearInterval(this.interval);
      this.showPrev();
      this.interval = window.setInterval(
        this.startCarousel,
        this.props.interval
      );
    }
  };

  toNext = () => {
    if (!this.state.sliding) {
      window.clearInterval(this.interval);
      this.startCarousel();
      this.interval = window.setInterval(
        this.startCarousel,
        this.props.interval
      );
    }
  };

  render() {
    // console.log(this.state);
    return (
      <div className="my-carousel">
        <div
          className="my-carousel-slides-all"
          onMouseEnter={this.stopCarousel}
          onMouseLeave={this.startCarouselOnMouseLeave}
        >
          {/* slides */}
          {this.renderSlides(this.state.direction)}
        </div>
        <button
          className="my-carousel-btn my-carousel-btn_left"
          onClick={this.toPrev}
        >
          <FaAngleLeft />
        </button>
        <button
          className="my-carousel-btn my-carousel-btn_right"
          onClick={this.toNext}
        >
          <FaAngleRight />
        </button>
      </div>
    );
  }
}

export default MyCarousel;
