import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Grid } from 'react-bootstrap';
import Helmet from 'react-helmet';
import { YMInitializer } from 'react-yandex-metrika';

import ItemsList from './components/pages/itemsList';
import Cart from './components/pages/cart';
import Suppliers from './components/pages/suppliers';
import SubList from './components/pages/SubList';
import categoryList from './components/pages/categoryList';
import ItemInfo from './components/pages/ItemInfo';
// import App from './components/App';

import Hero from './components/common/hero';
import StickyHeader from './components/common/stickyHeader';

import withSidebar from './components/common/hocWithSidebar';

// RETRIVES COMPONENTS BASED ON STATUS
const Status = function({ code, children }) {
  return (
    <Route
      render={function({ staticContext }) {
        if (staticContext) staticContext.status = code;
        return children;
      }}
    />
  );
};
//NOT-FOUND COMPONENT
const NotFound = function() {
  return (
    <Status code={404}>
      <div className="error-404">
        <h2>Ошибка: 404</h2>
        <p>Запрашиваемая страница не найдена.</p>
      </div>
    </Status>
  );
};

const routes = (
  <div>
    <Helmet
      defaultTitle="Stalin Pskov | Cтроительные материалы в г. Пскове"
      meta={[
        {
          name: 'description',
          content:
            'Компания Stalin Pskov специализируется на производстве и продаже  строительных материалов в г. Пскове',
        },
      ]}
    />

    {/* Yandex Metrika */}
    <YMInitializer
      accounts={[45450117]}
      options={{
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true,
      }}
    />
    <Hero />
    <StickyHeader />
    <Grid>
      <Switch>
        <Route exact path="/" component={categoryList} />
        <Route path="/special_offer" component={ItemsList} />
        <Route exact path="/categories/subs/:cat_id" component={SubList} />
        <Route path="/categories/subs/:cat_id/:id" component={ItemsList} />
        <Route path="/categories/:id" component={ItemsList} />
        <Route path="/cart" component={Cart} />
        <Route path="/for_suppliers" component={Suppliers} />
        <Route path="/product/:id" component={ItemInfo} />
        <Route component={withSidebar(NotFound)} />
      </Switch>
    </Grid>
  </div>
);

export default routes;
