import React from 'react';
import { Helmet } from 'react-helmet';

import './suppliers.scss';
import withSidebar from '../../common/hocWithSidebar';

const Suppliers = () => {
  return (
    <div>
      <Helmet>
        <title>Поставщикам | Stalin Pskov</title>
        <meta
          name="description"
          content="Информация для поставщиков | Stalin Pskov"
        />
      </Helmet>
      <h1 className="content__heading">Поставщикам</h1>
      <div className="suppliers-content">
        <div>
          <span className="suppliers-content__li">1</span> Если Вы круче
          Санта-Клауса{' '}
        </div>
        <div>
          <span className="suppliers-content__li">2</span> Если у Вас стальные
          яйца как у Брюса Уиллиса{' '}
        </div>
        <div>
          <span className="suppliers-content__li">3</span> Если Вы не ТП из
          менеджерского состава занимающаяся холодным обзвоном
        </div>
        <div>
          <span className="suppliers-content__li">4</span> Если Вы знаете то-что
          не знаем Мы - с радостью рассмотрим{' '}
        </div>
        <div className="suppliers-content__contact">
          Отдел закупок:
          <br /> т. +7(911) 9280577 <br /> stalinpskov@yandex.ru
        </div>
      </div>
    </div>
  );
};

export default withSidebar(Suppliers);
