import React from 'react';
import { connect } from 'react-redux';
import { Grid, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

import Item from './item';
import { setFilter } from '../../actions/filterActions';

import Sort from '../common/Sort';
import ItemsTable from '../common/ItemsTable';
import withSidebar from '../common/hocWithSidebar';
import { sortItems } from '../../helpers/sort';
import { getAllCategoriesSelector } from '../../selectors/categorySelector';
import { getAllProductsSelector } from '../../selectors/productSelectors';

class ItemsList extends React.Component {
  UNSAFE_componentWillMount() {
    this.getActiveCategoryFromRoute();
  }

  componentDidUpdate() {
    this.getActiveCategoryFromRoute();
  }

  getActiveCategoryFromRoute = () => {
    const activeCategoryArr = this.props.categories.filter(category => {
      return category._id === this.props.match.params.id;
    });

    let activeCategory;

    if (activeCategoryArr.length > 0) {
      activeCategory = activeCategoryArr[0].title;
    } else {
      activeCategory = 'Специальное предложение';
    }

    this.title = activeCategory;
    this.props.setFilter(activeCategory);
  };

  render() {
    const items = this.props.items.filter(item => {
      if (this.props.match.path === '/special_offer') {
        return item.onSale;
      } else {
        return (
          (item.categories && item.categories[0]) === this.props.match.params.id
        );
      }
    });

    const { value, direction } = this.props.sort;
    const sortedItems = sortItems(items, value, direction);

    let itemsList = sortedItems.map(item => {
      return (
        <Col xs={12} sm={6} md={4} key={item._id}>
          <Item
            _id={item._id}
            title={item.title}
            description={item.description}
            images={item.images}
            price={item.price}
            specialOffer={item.onSale}
            unit={item.unit}
            dimensions={item.dimensions}
          />
        </Col>
      );
    });

    if (this.props.view === 'list') {
      itemsList = <ItemsTable items={sortedItems} />;
    }

    const title = this.title;

    const metaDescription = `Вы можете приобрести ${title.toLowerCase()} на базе строительных материалов Stalin Pskov в г. Пскове по выгодным ценам.`;
    return (
      <Grid>
        {this.props.match.params.id ? (
          <Helmet>
            <title>{title} - купить в г. Пкове - Stalin Pskov</title>
            <meta name="description" content={metaDescription} />
          </Helmet>
        ) : null}
        <Row style={{ marginTop: '15px' }}>
          <h1 className="content__heading">{title}</h1>
        </Row>
        <Row>
          <Sort />
        </Row>
        <Row>{itemsList}</Row>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    items: getAllProductsSelector(state.products),
    filter: state.filter,
    categories: getAllCategoriesSelector(state.categories),
    view: state.sort.view,
    sort: state.sort.sort,
  };
}

export default withSidebar(
  connect(
    mapStateToProps,
    { setFilter }
  )(ItemsList)
);
