import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

// import logger from 'redux-logger';

import routes from './routes.js';
import store from './store.js';
import ScrollToTop from './components/scrollToTop';

// Styles
import './style/main.scss';

// WE WILL PASS INITIAL STATE FROM SERVER STORE

const Routes = (
  <Provider store={store}>
    <BrowserRouter>
      <ScrollToTop>{routes}</ScrollToTop>
    </BrowserRouter>
  </Provider>
);

render(Routes, document.getElementById('root'));
