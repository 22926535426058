import actions from '../actions/actions';

export function modalReducers(state=false, action) {
  switch(action.type) {

    case actions.CLOSE_MODAL:
      return false;

    case actions.OPEN_MODAL:
      return true;

    default:
      return state;
  }
}
