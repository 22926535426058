import React from 'react';
import { connect } from 'react-redux';
import { Modal, Panel, Col, Row, Button, Image } from 'react-bootstrap';
import {
  deleteCartItem,
  updateCart,
  getCart,
  sendOrder
} from '../../actions/cartActions';
import { closeModal } from '../../actions/modalActions';
import InputMask from 'react-input-mask';
import { findDOMNode } from 'react-dom';
import { Helmet } from 'react-helmet';

import withSidebar from '../common/hocWithSidebar';

class Cart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: props.showModal,
      invalid: false
    };
  }

  // componentDidMount() {
  //   this.props.getCart();
  // }

  open() {
    this.setState({
      showModal: true
    });
  }

  close() {
    this.props.closeModal();
  }

  onDelete(_id) {
    let booksToDelete = [...this.props.cart];
    let idxToDel = booksToDelete.findIndex(book => book._id === _id);
    let cartAfterDelete = [
      ...booksToDelete.slice(0, idxToDel),
      ...booksToDelete.slice(idxToDel + 1)
    ];

    this.props.deleteCartItem(cartAfterDelete);
  }

  onIncrement(_id) {
    this.props.updateCart(_id, 1, this.props.cart);
  }

  onDecrement(_id, qnt) {
    if (qnt > 1) {
      this.props.updateCart(_id, -1, this.props.cart);
    }
  }

  onSendOrder() {
    let tel = findDOMNode(this.refs.tel).value;
    const numberPattern = /\d+/g;
    const numbers = tel.match(numberPattern);

    let telString = Array.prototype.join.call(numbers, '');
    if (telString.length < 11) {
      this.setState({
        invalid: true
      });
    } else {
      this.setState({
        invalid: false,
        showModal: true
      });

      let order = {
        tel,
        items: this.props.cart,
        totalAmount: this.props.totalAmount
      };
      this.props.sendOrder(order);
    } // end else
  }

  renderEmpty() {
    return (
      <div>
        <Helmet>
          <title>Ваш заказ | Stalin Pskov</title>
          <meta
            name="description"
            content="Список товаров в Вашем заказе | Stalin Pskov"
          />
        </Helmet>
        <h3 className="cart--empty">Ваша корзина пуста.</h3>
        <Modal
          show={this.props.showModal}
          onHide={this.close.bind(this)}
          className="cart-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title className="cart-modal__title">Спасибо!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h6 className="cart-modal__body-header">Ваш заказ принят.</h6>
            <p className="cart-modal__body-text">
              Наш менеджер савяжется с Вами в ближайшее время.
            </p>
          </Modal.Body>
        </Modal>
      </div>
    );
  }

  renderCart() {
    const cartItemsList = this.props.cart.map(item => {
      return (
        <Panel key={item._id}>
          <Row>
            <Col xs={12} sm={2}>
              <Image src={`/api/img/${item.images[0]}`} responsive />
            </Col>
            <Col xs={12} sm={3}>
              <h6 className="cart__title">{item.title}</h6>
            </Col>
            <Col xs={12} sm={2}>
              <h6 className="cart__price">
                Цена: <span>{item.price} руб.</span>
              </h6>
            </Col>
            <Col xs={12} sm={3}>
              <h6 className="cart__qty">
                Кол-во:
                <span>
                  <Button
                    className="cart__btn-qty"
                    onClick={this.onDecrement.bind(
                      this,
                      item._id,
                      item.quantity
                    )}
                    bsStyle="default"
                    bsSize="small"
                  >
                    -
                  </Button>
                  {item.quantity}
                  <Button
                    className="cart__btn-qty"
                    onClick={this.onIncrement.bind(this, item._id)}
                    bsStyle="default"
                    bsSize="small"
                  >
                    +
                  </Button>
                </span>
              </h6>
            </Col>
            <Col xs={12} sm={2}>
              <Button
                className="cart__btn--delete"
                onClick={this.onDelete.bind(this, item._id)}
                bsStyle="danger"
                bsSize="small"
              >
                УДАЛИТЬ
              </Button>
            </Col>
          </Row>
          <div
            className="cart__delete-badge visible-xs"
            onClick={this.onDelete.bind(this, item._id)}
          >
            Удалить
          </div>
        </Panel>
      );
    }, this);

    return (
      <div className="cart">
        <div className="cart__sum-bage visible-xs">
          Сумма: <span>{this.props.totalAmount} руб.</span>
        </div>
        {cartItemsList}
        <Row>
          <Col xs={12}>
            <div className="cart__order">
              <h6 className="cart__sum">
                Сумма: <span>{this.props.totalAmount} руб.</span>
              </h6>

              <label>
                <span
                  className="cart__phone-label"
                  style={this.state.invalid ? { color: '#d00202' } : {}}
                >
                  Введите Ваш номер телефона:
                </span>
                <InputMask
                  ref="tel"
                  className="cart__phone-form"
                  mask="+7 (999) 999-99-99"
                  maskChar="_"
                  alwaysShowMask={true}
                  style={this.state.invalid ? { color: '#d00202' } : {}}
                />
              </label>

              <button
                onClick={this.onSendOrder.bind(this)}
                className="cart__btn-order"
              >
                Оформить<br />заказ
              </button>
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  render() {
    if (this.props.cart[0]) {
      return this.renderCart();
    } else {
      return this.renderEmpty();
    }
  }
}

function mapStateToProps(state) {
  return {
    cart: state.cart.cart,
    totalAmount: state.cart.totalAmount,
    showModal: state.modal
  };
}

export default withSidebar(
  connect(mapStateToProps, {
    deleteCartItem,
    updateCart,
    getCart,
    sendOrder,
    closeModal
  })(Cart)
);
