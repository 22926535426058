import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import Sidebar from './sidebar';

export default withSidebar;

function withSidebar(WrappedComponent) {
  class WithSidebar extends Component {
    static displayName = `${renderDisplayName(WrappedComponent)}_withSidebar`;

    render() {
      return (
        <Row className="padding-at-xs content-container">
          <Col sm={12} md={3}>
            <Sidebar />
          </Col>
          <Col sm={12} md={9}>
            <div className="content">
              <WrappedComponent {...this.props} />
            </div>
          </Col>
        </Row>
      );
    }
  }

  return WithSidebar;
}

function renderDisplayName(component) {
  return component.displayName || component.name || 'Component';
}
