import React, { Component } from 'react';
import { Grid, Row, Image, Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { getCart } from '../../actions/cartActions';

class Hero extends Component {
  render() {
    return (
      <Grid className="hero hidden-xs">
        <Row>
          <Image src="/images/hero_new_4g48jh.jpg" responsive />
          <div className="hero__icons">
            <Link className="hero__link" to="/for_suppliers">
              Поставщикам
            </Link>
            <a
              href="https://vk.com/stalinpskov"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image src="/images/vk.png" className="hero__icons-vk" />
            </a>
            <Link to="/cart">
              <div className="hero__cart">
                <Image src="/images/cart.png" className="hero__icons-cart" />
                {this.props.totalQty > 0 ? (
                  <Badge className="badge">{this.props.totalQty}</Badge>
                ) : (
                  ''
                )}
              </div>
            </Link>
          </div>
        </Row>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    totalQty: state.cart.totalQty,
  };
}

export default connect(
  mapStateToProps,
  { getCart }
)(Hero);
