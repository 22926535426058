import { createSelector } from 'reselect';

const categoryIdList = categories => categories.idList;
const categoryMap = categories => categories.map;
const productMap = (categories, products) => products.map;
const productId = (categories, products, id) => id;

const getAllCategories = (categoryIdList, categoryMap) =>
  categoryIdList.map(id => {
    const category = categoryMap[id];
    if (
      category &&
      category.subCategories &&
      category.subCategories.length > 0
    ) {
      category.subCategories = getAllCategories(
        category.subCategories,
        categoryMap
      );
    }
    return category;
  });

export const getAllCategoriesSelector = createSelector(
  categoryIdList,
  categoryMap,
  getAllCategories
);

export const getActiveCategoryByProductId = createSelector(
  categoryMap,
  productMap,
  productId,
  (categories, products, id) => {
    const categoryId = products[id] && products[id].categories[0];
    return categories[categoryId] && categories[categoryId].title;
  }
);
