import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { setFilter } from '../../actions/filterActions';
import { getCategories } from '../../actions/categoriesActions';
import { getProducts } from '../../actions/itemsActions';
import { getAllCategoriesSelector } from '../../selectors/categorySelector';

class Sidebar extends Component {
  state = {
    visible_xs: false,
    active: null
  };

  componentDidMount() {
    this.props.getProducts();
    this.props.getCategories();
  }

  renderItems = () => {
    let { categories } = this.props;

    return categories
      .filter(c => !c.parentCategories.length > 0)
      .sort((a, b) => {
        if (a.orderIdx < b.orderIdx) {
          return -1;
        } else if (a.orderIdx > b.orderIdx) {
          return 1;
        }
        return 0;
      })
      .map(({ title, _id, subCategories }) => {
        let matched = this.props.filter === title;

        if (!(subCategories && subCategories.length !== 0)) {
          return (
            <div key={_id}>
              <NavLink to={`/categories/${_id}`}>
                <h3
                  className={
                    matched ? 'sidebar__category active' : 'sidebar__category'
                  }
                  onClick={this.handleClick(title)}
                >
                  <span>{title}</span>
                </h3>
              </NavLink>
            </div>
          );
        } else {
          const subsTitles = subCategories.map(s => s.title);
          const subsMatched = subsTitles.indexOf(this.props.filter) > -1;
          return (
            <div
              key={_id}
              // onMouseEnter={this.setActiveCategory(_id)}
              // onMouseLeave={this.resetActiveCategory(title, subCategories)}
            >
              <NavLink to={`/categories/subs/${_id}`}>
                <h3
                  className={
                    matched ? 'sidebar__category active' : 'sidebar__category'
                  }
                  onClick={this.handleClick(title, true, _id)}
                >
                  <div className="col-sm-10">
                    <span>{title}</span>
                  </div>
                  <div className="col-sm-2">
                    <span
                      className="glyphicon glyphicon-chevron-down"
                      aria-hidden="true"
                    />
                  </div>
                </h3>
              </NavLink>
              <div
                className={`sidebar__subs ${
                  this.state.active === _id || matched || subsMatched
                    ? ''
                    : 'subs__hidden'
                }`}
              >
                {subCategories.map(s => {
                  matched = this.props.filter === s.title;
                  return (
                    <NavLink
                      to={`/categories/subs/${_id}/${s._id}`}
                      key={s._id}
                    >
                      <h3
                        className={
                          matched
                            ? 'sidebar__category active'
                            : 'sidebar__category'
                        }
                        onClick={this.handleClick(s.title, true, _id)}
                      >
                        <span>{s.title}</span>
                      </h3>
                    </NavLink>
                  );
                })}
              </div>
            </div>
          );
        }
      });
  };

  // пока отключен. onMouseEnter
  setActiveCategory = _id => () => {
    this.setState({
      active: _id
    });
  };

  // пока отключен. onMouseLeave
  resetActiveCategory = (title, subCategories) => () => {
    const { filter } = this.props;
    const subTitles = subCategories.map(s => s.title);
    if (filter !== title && subTitles.indexOf(filter) < 0) {
      this.setState({
        active: null
      });
    }
  };

  handleClick = (filter, isSubs, _id) => () => {
    this.props.setFilter(filter);
    this.setState({
      visible_xs: false,
      active: _id
    });
  };

  render() {
    let matched = this.props.filter === 'Специальное предложение';

    return (
      <div className="sidebar hidden-xs">
        <h2 className="sidebar__header">Каталог</h2>
        <NavLink to="/special_offer">
          <h3
            onClick={this.handleClick('Специальное предложение')}
            className={
              matched
                ? 'sidebar__special-offer active'
                : 'sidebar__special-offer'
            }
          >
            <span>Специальное предложение</span>
          </h3>
        </NavLink>
        {this.renderItems().length === 0 ? (
          <div className="loader">Loading...</div>
        ) : (
          this.renderItems()
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ filter, categories }) => ({
  filter,
  categories: getAllCategoriesSelector(categories)
});

export default connect(mapStateToProps, {
  getCategories,
  setFilter,
  getProducts
})(Sidebar);
