import actions from '../actions/actions';

export function cartReducers(state={cart:[]}, action) {
  switch(action.type) {

    case actions.GET_CART:
      return {
        ...state,
        cart: action.payload,
        totalAmount: totals(action.payload).amount,
        totalQty: totals(action.payload).qty
      };

    case actions.ADD_TO_CART:
      return { ...state,
        cart: [ ...action.payload ],
        totalAmount: totals(action.payload).amount,
        totalQty: totals(action.payload).qty
      };

    case actions.UPDATE_CART:
      return { ...state,
        cart: action.payload,
        totalAmount: totals(action.payload).amount,
        totalQty: totals(action.payload).qty
      };

    case actions.DELETE_CART_ITEM:
      return { ...state,
        cart: [ ...action.payload ],
        totalAmount: totals(action.payload).amount,
        totalQty: totals(action.payload).qty
      };

    case actions.SEND_ORDER:
      return {cart:[]};

    default:
      return state;
  }
}

function totals(payloadArr) {

  const total = payloadArr
    .map(item => item.price * item.quantity)
    .reduce((a, b) => a + b, 0);

  const totalQty = payloadArr.length;
    // .map(item => item.quantity)
    // .reduce( (a, b) => a + b, 0)

  return { amount: total.toFixed(2), qty: totalQty };

}
