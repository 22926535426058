import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

import SubListItem from './SubListItem';
import { setFilter } from '../../actions/filterActions';
import { getAllCategoriesSelector } from '../../selectors/categorySelector';
import { getAllProductsSelector } from '../../selectors/productSelectors';
import withSidebar from '../common/hocWithSidebar';

class SubList extends Component {
  componentDidMount() {
    this.getActiveCategoryFromRoute();
  }

  componentDidUpdate() {
    this.getActiveCategoryFromRoute();
  }

  renderSubs = () => {
    const { categories, match: { params } } = this.props;
    const category = categories.find(c => {
      if (c._id === params.cat_id) {
        return true;
      }
      return false;
    });

    return (
      category &&
      category.subCategories.map(s => {
        // const product = products.find(p => {
        //   if (p.categories.indexOf(s._id) > -1) {
        //     return true;
        //   }
        //   return false;
        // });

        return (
          <Col xs={12} sm={6} md={4} key={s._id}>
            <SubListItem title={s.title} imgSrc={s.img} id={s._id} />
          </Col>
        );
      })
    );
  };

  getActiveCategoryFromRoute = () => {
    const { categories, match: { params }, setFilter } = this.props;
    const category = categories.find(c => {
      if (c._id === params.cat_id) {
        return true;
      }
      return false;
    });
    if (category) {
      setFilter(category.title);
    }
  };

  render() {
    const title = this.props.filter;
    const metaDescription = `Вы можете приобрести ${title.toLowerCase()} на базе строительных материалов Stalin Pskov в г. Пскове по выгодным ценам.`;

    return (
      <Grid>
        <Helmet>
          <title>{title} - купить в г. Пкове - Stalin Pskov</title>
          <meta name="description" content={metaDescription} />
        </Helmet>

        <Row style={{ marginTop: '15px' }}>
          <h1 className="content__heading">{title}</h1>
        </Row>
        <Row>{this.renderSubs()}</Row>
      </Grid>
    );
  }
}

export default withSidebar(
  connect(
    ({ filter, categories, products }) => ({
      filter,
      categories: getAllCategoriesSelector(categories),
      products: getAllProductsSelector(products)
    }),
    { setFilter }
  )(SubList)
);
