import c from './actions';

export const setOrder = (value, direction) => ({
  type: c.SET_OREDER,
  payload: { value, direction }
});

export const setView = type => ({
  type: c.SET_VIEW,
  payload: type
});
