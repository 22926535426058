import actions from '../actions/actions';

const defaultState = {
  idList: [],
  map: {}
};

export const categoriesReducers = (state = defaultState, action) => {
  switch (action.type) {
    case actions.GET_CATEGORIES:
      return action.payload;
    default:
      return state;
  }
};
