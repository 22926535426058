import React, { Component } from 'react';
import FaTh from 'react-icons/lib/fa/th';
import FaThList from 'react-icons/lib/fa/th-list';
// import FaSortAlphaAsc from 'react-icons/lib/fa/sort-alpha-asc';
// import FaSortAlphaDesc from 'react-icons/lib/fa/sort-alpha-desc';
import FaLongArrowDown from 'react-icons/lib/go/arrow-down';
import FaLongArrowUp from 'react-icons/lib/go/arrow-up';
import { connect } from 'react-redux';
import { setOrder, setView } from '../../actions/sortActions';

class Sort extends Component {
  handleSort = (value, direction) => () => {
    this.props.setOrder(value, direction);
  };

  handleView = type => () => {
    this.props.setView(type);
  };

  // componentDidMount() {
  //   this.props.setOrder('price', 'asc');
  // }

  render() {
    const { view, sort } = this.props.sort;
    const order = `${sort.value}_${sort.direction}`;

    return (
      <div className="col-lg-12 hidden-xs">
        <div className="sort-container">
          <div>
            <span className="text">Вид: </span>
            <div
              className={`sort-btn sort-btn__long ${view === 'images'
                ? 'active'
                : ''}`}
              onClick={this.handleView('images')}
            >
              <span>
                Картинки <FaTh />
              </span>
            </div>
            <div
              className={`sort-btn sort-btn__long ${view === 'list'
                ? 'active'
                : ''}`}
              onClick={this.handleView('list')}
            >
              <span>
                Список <FaThList />
              </span>
            </div>
          </div>

          <div>
            <span className="text">Упорядочить по: </span>
            <div
              className={`sort-btn ${order === 'price_asc' ? 'active' : ''}`}
              onClick={this.handleSort('price', 'asc')}
            >
              <span>
                Цене<FaLongArrowUp className="sort-arrow" />
              </span>
            </div>
            <div
              className={`sort-btn ${order === 'price_desc' ? 'active' : ''}`}
              onClick={this.handleSort('price', 'desc')}
            >
              <span>
                Цене<FaLongArrowDown className="sort-arrow" />
              </span>
            </div>

            <div
              className={`sort-btn sort-btn__long ${order === 'title_asc'
                ? 'active'
                : ''}`}
              onClick={this.handleSort('title', 'asc')}
            >
              <span>
                Названию<FaLongArrowUp className="sort-arrow" />
              </span>
            </div>
            <div
              className={`sort-btn sort-btn__long ${order === 'title_desc'
                ? 'active'
                : ''}`}
              onClick={this.handleSort('title', 'desc')}
            >
              <span>
                Названию<FaLongArrowDown className="sort-arrow" />
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(({ sort }) => ({ sort }), { setOrder, setView })(Sort);
