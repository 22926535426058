import { createSelector } from 'reselect';

const productIdList = products => products.idList;
const productMap = products => products.map;
const productId = (products, productId) => productId;
const cart = (cart, productId) => cart;

const getAllProducts = (productIdList, productMap) =>
  productIdList.map(id => productMap[id]);

export const getAllProductsSelector = createSelector(
  productIdList,
  productMap,
  getAllProducts
);

export const getProductSelector = createSelector(
  productMap,
  productId,
  (map, id) => map[id]
);

export const isInCartSlector = createSelector(
  cart,
  productId,
  (cart, productId) => cart.some(item => item._id === productId)
);
