import c from '../actions/actions';

const defaultState = {
  sort: {
    value: 'price',
    direction: 'asc'
  },
  view: 'images'
};

export const sortReducers = (state = defaultState, action) => {
  switch (action.type) {
    case c.SET_OREDER:
      const { value, direction } = action.payload;
      return { ...state, sort: { value, direction } };

    case c.SET_VIEW:
      const view = action.payload;
      return { ...state, view };

    default:
      return state;
  }
};
