import React from 'react';
import { Link } from 'react-router-dom';

const SubListItem = ({ title, imgSrc, id, hasSub }) => {
  const link = hasSub ? `/categories/subs/${id}` : `/categories/${id}`;
  return (
    <Link to={link}>
      <div className="item category_item">
        <div
          className="item__img-contaner"
          style={{ backgroundImage: 'url(/api/img/' + imgSrc + ')' }}
        />
        <h6 className="item__title item__title--sublist">{title}</h6>
      </div>
    </Link>
  );
};

export default SubListItem;
