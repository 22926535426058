import React from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { findDOMNode } from 'react-dom';

import { setFilter } from '../../actions/filterActions';
import { getCategories } from '../../actions/categoriesActions';
import { getAllCategoriesSelector } from '../../selectors/categorySelector';

class Catalog extends React.Component {
  state = {
    visible_xs: false,
    hideOnScroll: false,
  };

  componentDidMount() {
    this.props.getCategories();
    window.addEventListener('scroll', this.hideBar);
  }

  hideBar = () => {
    if (!this.state.visible_xs) {
      let { hideOnScroll } = this.state;
      window.scrollY > this.prev
        ? !hideOnScroll && this.setState({ hideOnScroll: true })
        : hideOnScroll && this.setState({ hideOnScroll: false });

      this.prev = window.scrollY;
    }
  };

  componentWillUnmount() {
    window.removeEventListener('scroll', this.hideBar);
  }

  renderItems() {
    let { categories } = this.props;

    const nodes = categories
      .filter(c => c.isRoot)
      .sort((c1, c2) => (c1.orderIdx > c2.orderIdx ? 1 : -1))
      .map(({ title, _id, subCategories }) => {
        let matched = this.props.filter === title;
        let link =
          subCategories.length > 0
            ? `/categories/subs/${_id}`
            : `/categories/${_id}`;
        return (
          <NavLink to={link} key={_id}>
            <h3
              className={
                matched ? 'catalog__category active' : 'catalog__category'
              }
              onClick={this.handleClick.bind(this, title)}
            >
              {title}
            </h3>
          </NavLink>
        );
      });

    nodes.push(
      <NavLink key="for_suppliers" to="/for_suppliers">
        <h3
          className="catalog__category"
          onClick={this.handleClick.bind(this, 'for_suppliers')}
        >
          Поставщикам
        </h3>
      </NavLink>
    );

    return nodes;
  }

  handleClick(filter) {
    this.props.setFilter(filter);
    this.setState({
      visible_xs: false,
    });
  }

  showCatalog() {
    this.setState({
      visible_xs: !this.state.visible_xs,
    });
    const element = findDOMNode(this.refs.scroll_item);
    element.scrollTop += 200;

    setTimeout(() => {
      element.scrollIntoView();
    }, 1500);
  }

  render() {
    let matched = this.props.filter === 'Специальное предложение';

    return (
      <div
        className="catalog visible-xs-block"
        style={
          this.state.hideOnScroll
            ? { opacity: 0, visibility: 'hidden', height: 0 }
            : { opacity: 1 }
        }
      >
        <h2 className="catalog__header" onClick={this.showCatalog.bind(this)}>
          Каталог
          {this.state.visible_xs ? (
            <span
              className="glyphicon glyphicon-chevron-up visible-xs"
              aria-hidden="true"
            />
          ) : (
            <span
              className="glyphicon glyphicon-chevron-down visible-xs"
              aria-hidden="true"
            />
          )}
        </h2>
        <div
          className="catalog__items"
          style={this.state.visible_xs ? {} : { display: 'none' }}
          ref="scroll_item"
        >
          <NavLink to="/special_offer">
            <h3
              onClick={this.handleClick.bind(this, 'Специальное предложение')}
              className={
                matched
                  ? 'catalog__special-offer active'
                  : 'catalog__special-offer'
              }
            >
              Специальное предложение
            </h3>
          </NavLink>
          {this.renderItems()}
        </div>
      </div>
    );
  }
}

function mapStateToProps({ filter, categories }) {
  return {
    filter,
    categories: getAllCategoriesSelector(categories),
  };
}

export default connect(
  mapStateToProps,
  { getCategories, setFilter }
)(Catalog);
