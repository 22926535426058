import { combineReducers } from 'redux';

import { itemsReducers } from './itemsReducers';
import { cartReducers } from './cartReducers';
import { filterReducers } from './filterReducers';
import { categoriesReducers } from './categoriesReducers';
import { modalReducers } from './modalReducers';
import { sortReducers } from './sortReducers';

export default combineReducers({
  products: itemsReducers,
  categories: categoriesReducers,
  cart: cartReducers,
  filter: filterReducers,
  modal: modalReducers,
  sort: sortReducers
});
