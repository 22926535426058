import c from '../actions/actions';

const defaultState = {
  idList: [],
  map: {}
};

export function itemsReducers(state = defaultState, action) {
  switch (action.type) {
    case c.GET_PRODUCT:
      return action.payload;
    default:
      return state;
  }
}
